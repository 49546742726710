export const API_LOADING = 'API_LOADING';
export const DATA_FETCH_SUCCESS = 'DATA_FETCH_SUCCESS';
export const API_SUCCESS = 'API_SUCCESS';
export const ERROR_OCCURRED = 'ERROR_OCCURRED';
export const DATA_FETCH_FAILED = 'DATA_FETCH_FAILED';
export const NETWORK_ERROR = 'NETWORK_ERROR';

// user logiin

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_ERROR_OCCURRED = 'LOGIN_ERROR_OCCURRED';

//USER INVITATION

export const INVITE_SUCCESS = 'INVITE_SUCCESS';
export const INVITE_ERROR = 'INVITE_ERROR';
export const NETWORK_INVITE_ERROR = 'NETWORK_INVITE_ERROR';

//USER LINK
export const LINK_SUCCESS = 'LINK_SUCCESS';
export const LINK_ERROR = 'LINK_ERROR';
export const NETWORK_LINK_ERROR = 'NETWORK_LINK_ERROR';

//USER PAGE
export const PAGE_SUCCESS = 'PAGE_SUCCESS';
export const PAGE_ERROR = 'PAGE_ERROR';
export const NETWORK_PAGE_ERROR = 'NETWORK_PAGE_ERROR';



