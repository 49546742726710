import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

const TaskList = Loadable(lazy(() => import('views/tasks')));
const TaskDetails = Loadable(lazy(() => import('views/tasks/task-details')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// Admin Manage user
const InviteUser = Loadable(lazy(() => import('views/users/usersinvitation')));

const ReqUser = Loadable(lazy(() => import('views/users/userequest')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        // {
        //     path: '/',
        //     element: <DashboardDefault />
        // },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'adminusers',
            children: [
                {
                    path: 'invite-user',
                    element: <InviteUser />
                }
                
            ]
        },    
        {
            path: 'invitation',
            children: [
                
                {
                    path: 'check/:id',
                    element: <ReqUser />
                }
            ]
        },       
        {
            path: 'tasks',
            children: [
                {
                    path: 'today-task',
                    element: <TaskList taskType='today-task' />
                },
                {
                    path: 'weekly-task',
                    element: <TaskList taskType='weekly-task' />
                },
                {
                    path: 'monthly-task',
                    element: <TaskList taskType='monthly-task' />
                },
                {
                    path: 'overdue-task',
                    element: <TaskList taskType='overdue-task' />
                },
                {
                    path: 'unscheduled-task',
                    element: <TaskList taskType='unscheduled-task' />
                },
                {
                    path: 'completed-task',
                    element: <TaskList taskType='completed-task' />
                },
                {
                    path: 'pending-task',
                    element: <TaskList taskType='pending-task' />
                },
                {
                    path: 'task-history',
                    element: <TaskList taskType='task-history' />
                },
                {
                    path: 'private-task',
                    element: <TaskList taskType='private-task' />
                },
                {
                    path: 'task-details',
                    element: <TaskDetails taskType='task-details' />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
