// assets
import { 
    IconTypography, 
    IconPalette, 
    IconShadow, 
    IconWindmill,
    IconListCheck,
    IconCheckbox,
    IconAlarm,
    IconHistory,
    IconUserCircle
 } from '@tabler/icons';

// constant
/* const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill
}; */

// ==============================|| TASKS MENU ITEMS ||============================== //

const admin = {
    id: 'users',
    title: 'Users',
    caption: 'User Management',
    type: 'group',
    children: [
        {
            id: 'adminusers',
            title: 'Manage User',
            type: 'collapse',
            icon: IconListCheck,
            children: [
                {
                    id: 'invite-user',
                    title: 'Invite a User',
                    type: 'item',
                    url: '/adminusers/invite-user',
                    breadcrumbs: false
                }                                              
            ]
        },        
        {
            id: 'user-list',
            title: 'User List',
            type: 'item',
            url: '/adminusers/user-list',
            icon: IconCheckbox,
            breadcrumbs: false
        }      
    ]
};

export default admin;
