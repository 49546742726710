import axios from "axios";

const SDClient = {
    apiKey: "6fc8d34930b49ce9e28d4487632994cdaf170ef406a6f17742c17f7e0255e706",
    counter: 1,
    digest: async (message) => {
        return 0;
        /* return Array.prototype.map
            .call(
                new Uint8Array(
                    await crypto.subtle.digest(
                        "SHA-256",
                        new TextEncoder().encode(message)
                    )
                ),
                (x) => ("0" + x.toString(16)).slice(-2)
            )
            .join(""); */
    },
    encodeMessage: function (message) {
        return message;
    },
    endpoint: function (action, data = { _action: null }) {
        let service = this;
        return new Promise(function (onSuccess, onError) {
            service
                .digest(
                    service.counter + ":" + btoa(JSON.stringify(data))
                    // service.counter
                )
                .then((hash) => {
                    let message = {
                        message: service.encodeMessage(data),
                        session: localStorage.getItem("sd_session"),
                        checksum: hash,
                        apiKey: service.apiKey,
                        counter: service.counter,
                    };
                    axios
                        .post(global.postUrl + action, message, {
                            //onUploadProgress: (progressEvent) => console.log(progressEvent),
                        })
                        .then(
                            (response) => {
                                if (response.status != 200) {
                                    console.log(response);
                                    return;
                                }
                                data = response.data;
                                if (data.request && data.session) {
                                    localStorage.setItem("sd_session", data.session);
                                    onSuccess(data);
                                } else {
                                    onError({
                                        request: false,
                                        reason: "REQUEST_FAILED",
                                        data: data,
                                    });
                                }
                            },
                            (error) => {
                                console.warn("Request Failed with a ", error.response);
                                if (error.response) {
                                    if (error.response.status >= 500)
                                        onError({
                                            request: false,
                                            reason: "SERVER_ERROR",
                                        });
                                    else if (error.response.status >= 300)
                                        onError({
                                            request: false,
                                            reason: "API_ERROR",
                                        });
                                    else
                                        onError({
                                            request: false,
                                            reason: "NETWORK_ERROR",
                                        });
                                }
                                else
                                    onError({
                                        request: false,
                                        reason: "REQUEST_FAILED",
                                    });
                            }
                        );
                    service.counter++;
                });
        });
    }
}

export default SDClient