// assets
import { 
    IconTypography, 
    IconPalette, 
    IconShadow, 
    IconWindmill,
    IconListCheck,
    IconCheckbox,
    IconAlarm,
    IconHistory,
    IconUserCircle
 } from '@tabler/icons';

// constant
/* const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill
}; */

// ==============================|| TASKS MENU ITEMS ||============================== //

const tasks = {
    id: 'tasks',
    title: 'Tasks',
    caption: 'Tasks Management',
    type: 'group',
    children: [
        {
            id: 'schedule',
            title: 'Schedule',
            type: 'collapse',
            icon: IconListCheck,
            children: [
                {
                    id: 'today-task',
                    title: 'Today’s task',
                    type: 'item',
                    url: '/tasks/today-task',
                    breadcrumbs: false
                },
                {
                    id: 'weekly-task',
                    title: 'Weekly task',
                    type: 'item',
                    url: '/tasks/weekly-task',
                    breadcrumbs: false
                },
                {
                    id: 'monthly-task',
                    title: 'Monthly task',
                    type: 'item',
                    url: '/tasks/monthly-task',
                    breadcrumbs: false
                },
                {
                    id: 'overdue-task',
                    title: 'Overdue task',
                    type: 'item',
                    url: '/tasks/overdue-task',
                    breadcrumbs: false
                },
                {
                    id: 'unscheduled-task',
                    title: 'Unscheduled task',
                    type: 'item',
                    url: '/tasks/unscheduled-task',
                    breadcrumbs: false
                }                                                
            ]
        },        
        {
            id: 'completed-task',
            title: 'Completed task',
            type: 'item',
            url: '/tasks/completed-task',
            icon: IconCheckbox,
            breadcrumbs: false
        },
        {
            id: 'pending-task',
            title: 'Pending task',
            type: 'item',
            url: '/tasks/pending-task',
            icon: IconAlarm,
            breadcrumbs: false
        },
        {
            id: 'task-history',
            title: 'Task history',
            type: 'item',
            url: '/tasks/task-history',
            icon: IconHistory,
            breadcrumbs: false
        },
        {
            id: 'private-task',
            title: 'Private task',
            type: 'item',
            url: '/tasks/private-task',
            icon: IconUserCircle,
            breadcrumbs: false
        }       
    ]
};

export default tasks;
