import axios from "axios";

//Promo Code in PDP

export const userRegister = async post => {

    return await axios
        .post(global.postUrl + "user/register", post)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
}

//page 

export const userPageregiste = async post => {

    return await axios
        .get(global.postUrl + "role/show", post)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error) {
                return error;
            }
        });
}
