import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import reducer from './reducer';

// ==============================|| REDUX - MAIN STORE ||============================== //
const middlewares = [thunk]

let devtools = (x) => x
if (
    // process.env.NODE_ENV !== 'production' &&
    // process.browser &&
    window.__REDUX_DEVTOOLS_EXTENSION__
) {
    devtools = window.__REDUX_DEVTOOLS_EXTENSION__()
}

const initialState = {}

const store = createStore(
    reducer,
    initialState,
    compose(applyMiddleware(...middlewares)),
)

// const store = createStore(reducer);
const persister = 'Free';

export { store, persister };
