import * as consts from '../ActionTypes'

const initialState = {
  success: false,
  loading: false,
  sidebarShow: 'responsive',
  invitation: '',
  roles: []
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case consts.API_LOADING: {
      return {
        ...state,
        success: false,
        loading: true,
      }
    }
    case consts.INVITE_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
        invitation: {
          email: action.payload.message.email,
          token: action.payload.message.token
        }
      }
    } 
    case consts.INVITE_ERROR: {
      return {
        ...state,
        success: false,
        loading: false,
        invitation: {
          email: '',
          token:''
        }
      }
    }
    case consts.LINK_SUCCESS: {
      return {
        ...state,
        success: false,
        loading: false,
        invitation: {
          token: action.payload.message.token
        }
      }
    }
    case consts.LINK_ERROR: {
      return {
        ...state,
        success: false,
        loading: false,
        invitation: {
          token:''
        }
      }
    }
    case consts.PAGE_SUCCESS: {
      return {
        ...state,
        success: false,
        loading: false,
        roles: action.payload.data.data
      }
    }
    case consts.PAGE_ERROR: {
      return {
        ...state,
        success: false,
        loading: false,
        roles: []
      }
    }

    default: {
      return state 
    }
  }
}



