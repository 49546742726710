// action - customization reducer
import { toast } from 'react-toastify';
import SDClient from '../sd_core/client'
import {
    userRegister,
    userPageregiste
} from '../services'
import * as consts from './ActionTypes'
// import { useNavigate } from 'react-router-dom';

window.SD = SDClient


export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

export function getRegister(values) {
    return (dispatch) => {
        dispatch({
            type: consts.API_LOADING,
        })
        userRegister(values)
            .then((response) => {
                if (response.data) {
                    if (response.data.success === true) {
                        // console.log(JSON.stringify(response.data.profile))
                        return dispatch({
                            type: consts.DATA_FETCH_SUCCESS,
                            payload: response.data,

                        })
                    } else {
                        return dispatch({
                            type: consts.ERROR_OCCURRED,
                        })
                    }
                } else {
                    return dispatch({
                        type: consts.DATA_FETCH_FAILED,
                    })
                }
            })
            .catch((error) => {
                return dispatch({
                    type: consts.NETWORK_ERROR,
                    error: error,
                })
            })
    }
}

export function getLogin(values) {

    // const navigate = useNavigate();
    return (dispatch) => {
        dispatch({
            type: consts.API_LOADING, 
        })
        SDClient.endpoint("user/login", { email: values.email, password: values.password })
            .then(response => {
                // console.log(history.push)
                // this.context.history.push('/dashboard')
                if (response.message.data === true) {
                    toast.success("Login Successful")
                    // this.history.push("/dashboard")
                    return dispatch({
                        type: consts.LOGIN_SUCCESS,
                        payload: response,
                    })
                } else {
                    toast.error("Login Failed")
                    return dispatch({
                        type: consts.LOGIN_ERROR_OCCURRED
                    })
                }
            })
            .catch((error) => {
                // console.log("error")
                toast.error("Login Failedd")
                return dispatch({
                    type: consts.NETWORK_ERROR,
                    error: error,
                })
            })
    }
}

export function getInvit(user_invitation_email) {
    return (dispatch) => {
        dispatch({
            type: consts.API_LOADING, 
        })
        SDClient.endpoint("user/invitation/send", { user_invitation_email: user_invitation_email})
            .then(response => {
                // console.dir(response);
                if (response.message.success === true) {
                    toast.success("Invitaion sent Successful")
                    return dispatch({
                        type: consts.INVITE_SUCCESS,
                        payload: response,
                    })
                } else {
                    toast.error("user alredy existig")
                    return dispatch({
                        type: consts.INVITE_ERROR,
                        payload: '',
                    })
                }
            })
            .catch((error) => {
                toast.error("Login Failedd")
                return dispatch({
                    type: consts.NETWORK_INVITE_ERROR,
                    error: error,
                })
            })
    }
}

//lik
export function cheInvit() {
    return (dispatch) => {
        dispatch({
            type: consts.API_LOADING, 
        })
        SDClient.endpoint("user/invitation/token", {token: props.user_data.message.token })
            .then(response => {
                if (response.message.success === true) {
                    toast.success("use is ew")
                    return dispatch({
                        type: consts.LINK_SUCCESS,
                        payload: response,
                    })
                } else {
                    toast.error("use is ot ew")
                    return dispatch({
                        type: consts.LINK_ERROR,
                        payload: '',
                    })
                }
            })
            .catch((error) => {
                toast.error("espose Failedd")
                return dispatch({
                    type: consts.NETWORK_LINK_ERROR,
                    error: error,
                })
            })
    }
}

export function pageInvit() {
    return (dispatch) => {
        dispatch({
            type: consts.API_LOADING, 
        })
        userPageregiste()
            .then(response => {
                if (response.data.success === true) {
                    return dispatch({
                        type: consts.PAGE_SUCCESS,
                        payload: response,
                    })
                } else {
                    return dispatch({
                        type: consts.PAGE_ERROR,
                        payload: '',
                    })
                }
            })
            .catch((error) => {
                return dispatch({
                    type: consts.NETWORK_PAGE_ERROR,
                    error: error,
                })
            })
    }
}


