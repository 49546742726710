import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import tasks from './tasks';
import user from './user';
import admin from './admin';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    // items: [dashboard, tasks, pages, utilities, user, other]
    items: [dashboard, admin, tasks]
};

export default menuItems;
